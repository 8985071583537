.App {
  
}

/* override bootstrap navbar colors */
.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom,#2c4053 0,#2c4053 100%) !important; /* override background image gradient w/ flat color */
}
.App-header {
  background-color: #2c4053;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  color: white;
}
.container {
  padding-top: 15px;
}

.App-link {
  color: #61dafb;
}

.react-datetime-picker__wrapper {
  display: flex;
  border: 0px solid gray !important;
}

.react-date-picker__wrapper {
  display: flex;
  border: 0px solid gray !important;
}


.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.autocomplete-dropdown-container:empty {
  display: none;
}